import { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FaPlay,
  FaPause,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
  FaImage,
  FaVideo,
  FaQuoteLeft,
  FaStar,
} from 'react-icons/fa';
import constantObj from '../constant/constant';

// Sample data - replace with your actual content
const media = [
  {
    type: 'video',
    src: './asset/proof/vid-1.mp4',
    poster: '/New-logo.jpg',
    testimonial: {
      name: 'John D.',
      text: `This EA Bot helped me recover my trading account and now I'm making consistent profits!`,
      stars: 5,
    },
  },
  {
    type: 'video',
    src: './asset/proof/vid-2.mp4',

    poster: './asset/hero-3.jpg',
    testimonial: {
      name: 'Sarah M.',
      text: 'I passed my prop firm challenge on the first try using this EA Robot. Highly recommended!',
      stars: 5,
    },
  },
  {
    type: 'image',
    src: './asset/proof/proof-4.jpeg',
    alt: 'Trading results proof',
    caption: 'Consistent profits with our EA Robot',
  },
  {
    type: 'image',
    src: './asset/proof/proof-1.jpeg',
    alt: 'Trading account statement',
    caption: '95% monthly ROI achieved',
  },

  {
    type: 'image',
    src: './asset/proof/proof-2.jpeg',
    alt: 'Trading chart with bot entries',
    caption: 'Smart entry and exit points',
  },

  {
    type: 'image',
    src: './asset/proof/proof-3.jpeg',
    alt: 'Profit statistics',
    caption: 'Low drawdown performance',
  },
];

export default function MediaGallery() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeFilter, setActiveFilter] = useState('all');
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const filteredMedia =
    activeFilter === 'all'
      ? media
      : media.filter((item) => item.type === activeFilter);

  const openModal = (index) => {
    setSelectedItem(filteredMedia[index]);
    setIsPlaying(false);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsPlaying(false);
  };

  const handleVideoControl = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Navigation in modal
  const navigateMedia = (direction) => {
    const currentIndex = filteredMedia.findIndex(
      (item) => item === selectedItem
    );
    const newIndex =
      (currentIndex + direction + filteredMedia.length) % filteredMedia.length;
    setSelectedItem(filteredMedia[newIndex]);
    setIsPlaying(false);
  };

  return (
    <div
      id="reviews"
      className="relative p-4 w-full max-w-6xl my-16 mx-auto">
      {/* Header */}
      <div className="mb-12 text-center">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 bg-gradient-to-r from-green-800 to-green-600 bg-clip-text text-transparent">
          Real Results. Real Testimonials.
        </h1>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
          Don't just take our word for it. See the proof and hear from our
          satisfied customers who have transformed their trading with our EA
          Robot.
        </p>

        {/* Filter buttons */}
        <div className="flex justify-center mt-6 gap-4">
          <button
            onClick={() => setActiveFilter('all')}
            className={`px-4 py-2 rounded-full transition-all ${
              activeFilter === 'all'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}>
            All
          </button>
          <button
            onClick={() => setActiveFilter('image')}
            className={`px-4 py-2 rounded-full transition-all flex items-center gap-2 ${
              activeFilter === 'image'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}>
            <FaImage /> Results
          </button>
          <button
            onClick={() => setActiveFilter('video')}
            className={`px-4 py-2 rounded-full transition-all flex items-center gap-2 ${
              activeFilter === 'video'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}>
            <FaVideo /> Testimonials
          </button>
        </div>
      </div>

      {/* Instructions indicator */}
      <div className="absolute top-[-20px] right-4 bg-blue-600 text-white px-4 py-2 rounded-full animate-pulse flex items-center gap-2">
        <span className="hidden sm:inline">Click on any item to enlarge!</span>{' '}
        👆
      </div>

      {/* Gallery grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
        {filteredMedia.map((item, index) => (
          <motion.div
            key={index}
            className="relative overflow-hidden rounded-xl shadow-lg bg-white"
            whileHover={{
              scale: 1.03,
              boxShadow: '0 10px 25px rgba(0,0,0,0.2)',
            }}
            transition={{ duration: 0.3 }}
            onClick={() => openModal(index)}>
            {item.type === 'image' ? (
              <div className="relative h-64 w-full">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-full h-full object-cover"
                />
                {item.caption && (
                  <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/80 to-transparent p-4">
                    <p className="text-white text-sm">{item.caption}</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative h-64 w-full group">
                <img
                  src={item.poster}
                  alt="Video thumbnail"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/30 flex items-center justify-center opacity-90 group-hover:opacity-100 transition-opacity">
                  <div className="w-16 h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center">
                    <FaPlay className="text-white text-xl ml-1" />
                  </div>
                </div>
                {item.testimonial && (
                  <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/80 to-transparent p-4">
                    <div className="flex items-center gap-1 mb-1">
                      {[...Array(item.testimonial.stars)].map((_, i) => (
                        <FaStar
                          key={i}
                          className="text-yellow-400 text-sm"
                        />
                      ))}
                    </div>
                    <p className="text-white text-sm font-medium">
                      {item.testimonial.name}
                    </p>
                  </div>
                )}
              </div>
            )}
          </motion.div>
        ))}
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600/10 to-purple-600/10 rounded-2xl p-6 mb-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <div>
            <h2 className="text-2xl font-bold mb-2">
              Ready to transform your trading?
            </h2>
            <p className="text-gray-600">
              Join hundreds of satisfied traders using our EA Robot
            </p>
          </div>
          <a
            href={constantObj.TELEGRAM}
            className="px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium rounded-lg hover:shadow-lg transition-all">
            Get Started Today
          </a>
        </div>
      </div>

      {/* Modal */}
      <AnimatePresence>
        {selectedItem && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/90 p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            {/* Close button */}
            <button
              className="absolute top-4 right-4 w-10 h-10 rounded-full bg-white/10 flex items-center justify-center text-white hover:bg-white/20 transition-colors z-20"
              onClick={closeModal}>
              <FaTimes />
            </button>

            {/* Navigation buttons */}
            <button
              className="absolute left-4 top-1/2 transform -translate-y-1/2 w-12 h-12 rounded-full bg-white/10 flex items-center justify-center text-white hover:bg-white/20 transition-colors z-20"
              onClick={() => navigateMedia(-1)}>
              <FaChevronLeft />
            </button>
            <button
              className="absolute right-4 top-1/2 transform -translate-y-1/2 w-12 h-12 rounded-full bg-white/10 flex items-center justify-center text-white hover:bg-white/20 transition-colors z-20"
              onClick={() => navigateMedia(1)}>
              <FaChevronRight />
            </button>

            <div className="max-w-4xl w-full">
              <motion.div
                key={selectedItem.src}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="relative">
                {selectedItem.type === 'image' ? (
                  <img
                    src={selectedItem.src}
                    alt={selectedItem.alt}
                    className="max-w-full max-h-[70vh] object-contain mx-auto rounded-lg"
                  />
                ) : (
                  <div className="relative">
                    <video
                      ref={videoRef}
                      src={selectedItem.src}
                      poster={selectedItem.poster}
                      className="max-w-full max-h-[70vh] object-contain mx-auto rounded-lg"
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      controls={false}
                    />
                    <button
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center hover:bg-white/30 transition-colors"
                      onClick={handleVideoControl}>
                      {isPlaying ? (
                        <FaPause className="text-white text-xl" />
                      ) : (
                        <FaPlay className="text-white text-xl ml-1" />
                      )}
                    </button>
                  </div>
                )}

                {/* Modal caption/testimonial */}
                <div className="mt-4 bg-white rounded-lg p-4">
                  {selectedItem.type === 'image' && selectedItem.caption && (
                    <p className="text-gray-800">{selectedItem.caption}</p>
                  )}

                  {selectedItem.type === 'video' &&
                    selectedItem.testimonial && (
                      <div className="flex flex-col">
                        <div className="flex items-center gap-1 mb-2">
                          {[...Array(selectedItem.testimonial.stars)].map(
                            (_, i) => (
                              <FaStar
                                key={i}
                                className="text-yellow-400"
                              />
                            )
                          )}
                        </div>
                        <div className="flex items-start gap-3">
                          <FaQuoteLeft className="text-blue-600 text-xl flex-shrink-0 mt-1" />
                          <div>
                            <p className="text-gray-800 italic mb-2">
                              {selectedItem.testimonial.text}
                            </p>
                            <p className="text-gray-600 font-medium">
                              - {selectedItem.testimonial.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
