import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      id="faq"
      className="border-b w-full mx-auto sm:w-4/5 md:w-1/2 border-gray-300 my-6">
      <div
        className="flex justify-between items-center py-2 cursor-pointer hover:bg-gray-100 transition duration-200"
        onClick={() => setIsOpen(!isOpen)}>
        <h3 className="text-xs sm:text-sm font-semibold text-black">
          {question}
        </h3>
        <span
          className={`transform transition-transform duration-300 ${
            isOpen ? 'rotate-180' : ''
          }`}>
          ▼
        </span>
      </div>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="text-gray-700 text-sm overflow-hidden">
          {answer}
        </motion.div>
      )}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: 'What is the UnbeatableFx Robot?',
      answer:
        'The UnbeatableFx Robot is an advanced trading algorithm designed to automate trading decisions in the forex market. Utilizing sophisticated algorithms and real-time market analysis, it aims to enhance trading performance and maximize profits for users.',
    },
    {
      question: 'How does the UnbeatableFx Robot work?',
      answer:
        'The Robot analyzes market trends, patterns, and indicators to execute trades on your behalf. It operates 24/7, ensuring that you never miss a trading opportunity while following your predefined risk management strategies and trading preferences.',
    },
    {
      question:
        'Do I need prior trading experience to use the Unbeatable Fx Robot?',
      answer:
        'No, you do not need any prior trading experience. The Robot is designed to be user-friendly, making it accessible for both beginners and experienced traders. Comprehensive tutorials and support materials are available to help you get started.',
    },
    {
      question:
        'Can I customize the trading strategies of the UnbeatableFx Robot?',
      answer:
        'Yes, the UnbeatableFx Robot allows for customization of trading strategies. Users can adjust parameters such as risk levels, trade volume, and preferred currency pairs to align with their trading goals and risk appetite.',
    },
    {
      question: 'Is my investment secure with the UnbeatableFx Robot?',
      answer:
        "While the UnbeatableFx Robot employs advanced algorithms to manage trades, it’s important to note that all investments carry risk. We recommend using proper risk management techniques and starting with a demo account to familiarize yourself with the robot's capabilities before investing real money.",
    },
  ];

  return (
    <div
      id="faq"
      className="bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-black mb-6 text-center">
        Frequently Asked Questions
      </h2>
      <div>
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
