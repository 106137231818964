import { motion } from 'framer-motion';
export default function Logo() {
  return (
    <motion.div className="flex  items-center max-w-[100px]  rounded-xl p-2 ">
      <img
        src="/New-logo.jpg"
        alt="unbeatable fx logo"
        className="object-cover w-full h-full rounded-xl"
      />
    </motion.div>
  );
}
