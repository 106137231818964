import React from 'react';
import {
  FaInstagram,
  FaTiktok,
  FaTelegram,
  FaFacebook,
  FaDiscord,
  FaTwitter,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import Logo from './logo';
import constantObj from '../constant/constant';

const partnerLogos = [
  './assets/top-forex-trading.png',
  './assets/partner-2.png',
  './assets/partner-3.png',
];

export default function Footer() {
  return (
    <footer className="bg-gray-800 py-12 text-white">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between mb-8">
          {/* Logo and Description */}
          <div className="mb-4 md:mb-0 my-6">
            <h3 className="text-lg font-bold mb-2">
              <Logo />
            </h3>
            <p className="text-sm mb-4">
              Your go-to solution for trading and investments.
            </p>
          </div>

          {/* Quick Links */}
          <div className="mb-4">
            <h4 className="text-md font-semibold my-6">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="#about"
                  className="hover:text-green-500"
                  title="Learn more about us">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#reviews"
                  className="hover:text-green-500"
                  title="Explore our services">
                  Reviews
                </a>
              </li>
              <li id="contact">
                <a
                  href="#contact"
                  className="hover:text-green-500"
                  title="Get in touch with us">
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="#faq"
                  className="hover:text-green-500"
                  title="Frequently Asked Questions">
                  FAQ
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div>
            <h4 className="text-md font-semibold my-6">Follow Us</h4>
            <div className="flex space-x-4">
              <a
                href={constantObj.TELEGRAM}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-500"
                title="Follow us on Telegram">
                <FaTelegram className="w-6 h-6" />
              </a>

              <a
                href={constantObj.INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-500"
                title="Follow us on Instagram">
                <FaInstagram className="w-6 h-6" />
              </a>
              <a
                href={constantObj.TIKTOK}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-500"
                title="Follow us on Tiktok">
                <FaTiktok className="w-6 h-6" />
              </a>
              <a
                href={constantObj.TWITTER}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-500"
                title="Follow us on Facebook">
                <FaTwitter className="w-6 h-6" />
              </a>
              <a
                href={constantObj.DISCORD}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-500"
                title="Follow us on Facebook">
                <FaDiscord className="w-6 h-6" />
              </a>
              <a
                href={constantObj.FACEBOOK_CHANNEL}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-500"
                title="Follow us on Facebook">
                <FaFacebook className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        {/* Partner Logos */}
        {/* <div className="flex justify-center mb-8">
          {partnerLogos.map((logo, index) => (
            <motion.img
              key={index}
              src={logo}
              alt={`Partner ${index + 1}`}
              className="h-12 mx-4"
              whileHover={{ scale: 1.1 }}
            />
          ))}
        </div> */}

        {/* Footer Bottom */}
        <div className="border-t border-gray-700 pt-4">
          <p className="text-center text-sm">
            &copy; {new Date().getFullYear()} UnbeatableFx
            <b className="text-green-500 italic">Robot</b>. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
