import { useState, useEffect } from 'react';
import {
  FaCheckCircle,
  FaChevronLeft,
  FaChevronRight,
  FaPause,
  FaPlay,
  FaRobot,
  FaChartLine,
  FaShieldAlt,
  FaClock,
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const benefits = [
  { text: 'Easy To Install', icon: <FaCheckCircle /> },
  {
    text: 'Robot allows both MT4 and MT5 trading platforms And Other Trading Platforms',
    icon: <FaRobot />,
  },
  { text: 'Give up to 95% ROI monthly', icon: <FaChartLine /> },
  {
    text: 'It works perfectly on any broker of your choice',
    icon: <FaCheckCircle />,
  },
  { text: 'The EA Bot Trades On Many Different Pairs', icon: <FaRobot /> },
  { text: 'Very low (Drawdown) DD of 3-5% maximum', icon: <FaShieldAlt /> },
  {
    text: 'The EA works on Live Trading Account and is used to Pass prop firm challenge',
    icon: <FaCheckCircle />,
  },
  { text: 'Unlimited License Available', icon: <FaCheckCircle /> },
  { text: '99% winning rate', icon: <FaChartLine /> },
  {
    text: 'It is perfectly used to recover the loss of trading accounts',
    icon: <FaShieldAlt />,
  },
  {
    text: 'It has Equity Protection and is built with accurate risk management',
    icon: <FaShieldAlt />,
  },
  { text: 'Robot works throughout 24/7', icon: <FaClock /> },
  {
    text: 'According to customer demand, Robot comments name can be changed',
    icon: <FaRobot />,
  },
];

const BenefitsSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % benefits.length);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [isPaused]);

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  const handlePauseToggle = () => {
    setIsPaused(!isPaused);
  };

  return (
    <div className="flex flex-col items-center justify-center h-fit bg-gradient-to-br from-gray-900 to-gray-800 p-6">
      <div className="w-full max-w-3xl overflow-hidden rounded-2xl shadow-2xl bg-gray-800 border border-gray-700">
        <div className="bg-gradient-to-r from-blue-600 to-purple-600 p-4 flex items-center justify-center">
          <FaRobot className="text-white text-2xl mr-2" />
          <h2 className="text-center text-2xl font-bold text-white">
            EA Robot Benefits
          </h2>
        </div>

        <div className="relative p-6 pb-16">
          {/* Progress bar */}
          <div className="absolute top-0 left-0 w-full h-1 bg-gray-700">
            <motion.div
              className="h-full bg-green-400"
              initial={{ width: 0 }}
              animate={{
                width: `${(activeIndex / (benefits.length - 1)) * 100}%`,
              }}
              transition={{ duration: 0.3 }}
            />
          </div>

          {/* Main content area */}
          <div className="relative min-h-32 mb-4 mt-4">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex items-center gap-4">
                <div className="flex-shrink-0 w-12 h-12 bg-green-500 rounded-full flex items-center justify-center text-white text-2xl">
                  {benefits[activeIndex].icon}
                </div>
                <div className="flex-grow">
                  <p className="text-lg font-medium text-white">
                    {benefits[activeIndex].text}
                  </p>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Control buttons */}
          <div className="absolute bottom-4 left-0 right-0 flex items-center justify-between px-6">
            <button
              onClick={() =>
                goToSlide((activeIndex - 1 + benefits.length) % benefits.length)
              }
              className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
              <FaChevronLeft className="h-5 w-5" />
            </button>

            <div className="flex items-center space-x-2">
              {benefits.map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => goToSlide(idx)}
                  className={`w-2 h-2 rounded-full ${
                    idx === activeIndex ? 'bg-green-400' : 'bg-gray-600'
                  }`}
                  aria-label={`Go to slide ${idx + 1}`}
                />
              ))}
            </div>

            <button
              onClick={handlePauseToggle}
              className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
              {isPaused ? (
                <FaPlay className="h-5 w-5" />
              ) : (
                <FaPause className="h-5 w-5" />
              )}
            </button>

            <button
              onClick={() => goToSlide((activeIndex + 1) % benefits.length)}
              className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
              <FaChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-8 max-w-xl text-center text-gray-400">
        <h3 className="text-xl font-semibold text-white mb-2 flex items-center justify-center">
          <FaRobot className="mr-2 text-green-400" /> Why Choose Our EA Robot?
        </h3>
        <p className="flex items-center justify-center">
          <FaShieldAlt className="text-blue-400 mr-2" />
          Our EA Robot is designed with advanced algorithms to optimize your
          trading strategy and maximize profits while minimizing risks.
        </p>
      </div>
    </div>
  );
};

export default BenefitsSlider;
